import {
  LearnItemType,
  LinearLearnItem,
  TheoryLearnItem, VideoLearnItem,
} from '@/components/platform/Learn/pages/LinearCourseTopic/LinearPage.typedefs';
import {
  sortLearnItems,
} from '@/components/platform/Learn/LinearFlow/helpers/sortLearnItems';

export interface LearnItemsMappedByType {
  learnItems: LinearLearnItem[];
  optionalLearnItems: LinearLearnItem[];
  requiredLearnItems: LinearLearnItem[];
  theoryLearnItems: TheoryLearnItem[];
  videoLearnItems: VideoLearnItem[];
  deletedLearnItems: LinearLearnItem[];
}

export const mapLearnItemsByType = (
  learnItems: LinearLearnItem[],
): LearnItemsMappedByType => {
  const sortedLearnItems = sortLearnItems(learnItems);

  return sortedLearnItems.reduce<LearnItemsMappedByType>(
    (acc, item) => {
      if (item.isOptional && !item.isDeleted) {
        acc.optionalLearnItems.push(item);
      }

      if (!item.isOptional && !item.isDeleted) {
        acc.requiredLearnItems.push(item);
      }

      if (item.isDeleted) {
        acc.deletedLearnItems.push(item);
      }

      if (item.type === LearnItemType.Theory) {
        acc.theoryLearnItems.push(item);
      }

      if (item.type === LearnItemType.Video) {
        acc.videoLearnItems.push(item);
      }

      return acc;
    },
    {
      learnItems: [...sortedLearnItems],
      optionalLearnItems: [],
      requiredLearnItems: [],
      theoryLearnItems: [],
      videoLearnItems: [],
      deletedLearnItems: [],
    },
  );
};
