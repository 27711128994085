import { NextRouter } from 'next/router';
import {
  getQueryParamsByLearnItem,
} from '@/components/platform/Learn/LinearFlow/helpers/getQueryParamsByLearnItem';
import {
  URLByTopicSection,
} from '@/components/platform/Learn/CourseTopic/URLByTopicSection';
import {
  LinearLearnItem,
} from '@/components/platform/Learn/pages/LinearCourseTopic/LinearPage.typedefs';
import {
  LearnQueryParams, LearnQueryParamsKeys,
} from '@/components/platform/Learn/hooks/useLearnQueryParams';
import {
  LearnItemFilter,
} from '@/components/platform/Learn/LinearFlow/hooks/learnItemsFilter/useLearnItemsFilter';

export const setQueryParamsByLearnItem = (options: {
  router: NextRouter;
  learnItem?: LinearLearnItem | null;
  currentLearnQueryParams: LearnQueryParams;
  isEmptyParamsAvailable?: boolean;
  learnItemsFilter?: LearnItemFilter;
  queryParamsToSet?: Partial<LearnQueryParams>;
}) => {
  const {
    router,
    learnItem,
    currentLearnQueryParams,
    isEmptyParamsAvailable = false,
    learnItemsFilter,
    queryParamsToSet: queryParamsProps = {},
  } = options;

  const queryParamsData = getQueryParamsByLearnItem(learnItem);

  if (!queryParamsData) {
    const query = {
      ...router?.query,
      section: undefined,
      theoryId: undefined,
      videoId: undefined,
      testTaskSlug: undefined,
      ...queryParamsProps,
    };

    const next: () => Promise<any> = () => router?.push({
      pathname: router.pathname,
      query,
    });

    router?.events.emit('beforeHistoryChange', {
      url: router?.asPath,
      query,
      next,
    });

    next();

    return;
  }

  const hasPrevParams = currentLearnQueryParams.section && (
    currentLearnQueryParams.videoId
    || currentLearnQueryParams.testTaskSlug
    || currentLearnQueryParams.theoryId
  );

  const setFunction = hasPrevParams || isEmptyParamsAvailable
    ? router?.push
    : router?.replace;

  if (!setFunction) {
    return;
  }

  const query = {
    ...router.query,
    ...queryParamsData,
    section: URLByTopicSection[queryParamsData.section],
    ...(learnItemsFilter && {
      [LearnQueryParamsKeys.LearnItemsFilter]: learnItemsFilter,
    }),
    ...queryParamsProps,
  };

  const next: () => Promise<any> = () => setFunction({
    pathname: router.pathname,
    query,
  });

  router?.events.emit('beforeHistoryChange', {
    url: router?.asPath,
    query,
    next,
  });

  next();
};
