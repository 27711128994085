import React, {
  createContext,
  FC,
  memo,
  useContext,
  useMemo,
} from 'react';
import {
  useSelectLearnItem,
} from '@/components/platform/Learn/LinearFlow/hooks/useSelectLearnItem';
import {
  LinearLearnItem,
} from '@/components/platform/Learn/pages/LinearCourseTopic/LinearPage.typedefs';
import {
  useMappedByTypeLearnItems,
} from '@/components/platform/Learn/LinearFlow/hooks/useMappedByTypeLearnItems';
import { emptyAsyncFunction, emptyFunction } from '@/lib/helpers/functional';
import {
  useLinearFlowConfigContext,
} from '@/components/platform/Learn/LinearFlow/LinearFlowContextProviders/LinearFlowConfigContext/LinearFlowConfigContext';

export interface LearnItemsContextType {
  learnItems: LinearLearnItem[];
  selectedLearnItem: LinearLearnItem | null;
  selectLearnItem: (learnItemId: number | null) => void;
  lastOpenedLearnItemId: number | null;
  /** @description
   * This function will be called when the user solves all items and clicks the next button.
   * If not passed the next button will be hidden in the last solved item.
   * examples: openNextTopic, openCompletionScreen
   */
  onRequestNextItems?: () => void;
}

export const LearnItemsContext = createContext<LearnItemsContextType>({
  learnItems: [],
  selectedLearnItem: null,
  selectLearnItem: emptyFunction,
  lastOpenedLearnItemId: null,
});

export const useLearnItemsContext = () => useContext(LearnItemsContext);

export interface LearnItemsContextProviderProps {
  children: React.ReactNode;
  onChangeSelectedLearnItem?: (
    learnItem: LinearLearnItem | null,
  ) => Promise<void>;
  isMobile: boolean;
  /** @description
   * This function will be called when the user solves all items and clicks the next button.
   * If not passed the next button will be hidden in the last solved item.
   * examples: openNextTopic, openCompletionScreen
   */
  onRequestNextItems?: () => void;
  learnItems: LinearLearnItem[];
  lastOpenedLearnItemId?: number | null;
}

export const LearnItemsContextProvider: FC<
  LearnItemsContextProviderProps
> = memo((props) => {
  const {
    children,
    onChangeSelectedLearnItem = emptyAsyncFunction,
    lastOpenedLearnItemId = null,
    isMobile,
    onRequestNextItems,
  } = props;

  const { learnItems } = useMappedByTypeLearnItems(props.learnItems);

  const {
    shouldItemBeSelectedOnMobileInitially,
  } = useLinearFlowConfigContext();

  const {
    selectedLearnItemId,
    selectLearnItem,
  } = useSelectLearnItem({
    learnItems,
    onChangeSelectedLearnItem,
    lastOpenedLearnItemId,
    isMobile,
    shouldItemBeSelectedOnMobileInitially,
  });

  const selectedLearnItem = useMemo(
    () => learnItems.find(
      (learnItem) => learnItem.id === selectedLearnItemId,
    ) || null,
    [learnItems, selectedLearnItemId],
  );

  const value: LearnItemsContextType = useMemo(() => ({
    learnItems,
    selectedLearnItem,
    selectLearnItem,
    lastOpenedLearnItemId,
    onRequestNextItems,
  }), [
    learnItems,
    selectLearnItem,
    selectedLearnItem,
    lastOpenedLearnItemId,
    onRequestNextItems,
  ]);

  return (
    <LearnItemsContext.Provider value={value}>
      {children}
    </LearnItemsContext.Provider>
  );
});
