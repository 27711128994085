import { useCallback } from 'react';
import {
  LearnQueryParamsKeys,
  useLearnQueryParams,
} from '@/components/platform/Learn/hooks/useLearnQueryParams';
import { useRouter } from '@/middleware/i18n';
import {
  useLearnItemsContext,
} from '@/components/platform/Learn/LinearFlow/LinearFlowContextProviders/LearnItemsContext/LearnItemsContext';
import {
  useMappedByTypeLearnItems,
} from '@/components/platform/Learn/LinearFlow/hooks/useMappedByTypeLearnItems';
import {
  LearnItemType,
} from '@/components/platform/Learn/pages/LinearCourseTopic/LinearPage.typedefs';
import {
  setQueryParamsByLearnItem,
} from '@/components/platform/Learn/LinearFlow/helpers/setQueryParamsByLearnItem';

export enum LearnItemFilter {
  Theory = 'Theory',
  Video = 'Video',
  All = 'All',
}

interface HookOutput {
  learnItemFilter: LearnItemFilter;
  enableTheoryFilter: () => void;
  enableVideoFilter: () => void;
  disableFiltering: () => void;
}

export const useLearnItemsFilter = (): HookOutput => {
  const learnQueryParams = useLearnQueryParams();
  const router = useRouter();
  const {
    selectedLearnItem,
    learnItems,
  } = useLearnItemsContext();
  const { theoryLearnItems, videoLearnItems } = useMappedByTypeLearnItems(
    learnItems,
  );

  const disableFiltering = useCallback(() => {
    router.push({
      pathname: router.pathname,
      query: {
        ...router.query,
        [LearnQueryParamsKeys.LearnItemsFilter]: LearnItemFilter.All,
      },
    });
  }, [router]);

  const enableFilter = useCallback((
    learnItemFilter: LearnItemFilter.Video | LearnItemFilter.Theory,
  ) => {
    const learnItemsByFilter = learnItemFilter === LearnItemFilter.Video
      ? videoLearnItems
      : theoryLearnItems;

    const expectedItemType = learnItemFilter === LearnItemFilter.Video
      ? LearnItemType.Video
      : LearnItemType.Theory;

    const selectedItemByFilter = selectedLearnItem?.type === expectedItemType
      ? selectedLearnItem
      : learnItemsByFilter[0];

    if (!selectedItemByFilter) {
      disableFiltering();

      return;
    }

    setQueryParamsByLearnItem({
      router,
      learnItem: selectedItemByFilter,
      learnItemsFilter: learnItemFilter,
      currentLearnQueryParams: learnQueryParams,
    });
  }, [
    learnQueryParams,
    router,
    selectedLearnItem,
    theoryLearnItems,
    videoLearnItems,
    disableFiltering,
  ]);

  const enableTheoryFilter = useCallback(() => {
    enableFilter(LearnItemFilter.Theory);
  }, [enableFilter]);

  const enableVideoFilter = useCallback(() => {
    enableFilter(LearnItemFilter.Video);
  }, [enableFilter]);

  return {
    enableTheoryFilter,
    enableVideoFilter,
    disableFiltering,
    learnItemFilter: learnQueryParams.learnItemsFilter,
  };
};
