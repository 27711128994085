import {
  CourseAccessBlockReason,
  CourseAccessType,
  ModuleType,
  PeerReviewStatus,
  SupportProviderName,
  TestTaskFlow,
  TestTaskStatus,
} from '@/controllers/graphql/generated';
import {
  CourseTopicWithMetaForLinearPageFragment,
  CourseUserTopicBaseForLinearFragment,
} from '@/components/platform/Learn/pages/LinearCourseTopic/graphql/generated/linearTopicPage.fragment.generated';
import { MappedTaskConfig } from '@/controllers/testTasks/testTasks.hooks/useTestTaskConfigsMap';
import { TestTaskConfigFragment } from '@/components/platform/TestTasks/graphql/generated/testTaskConfig.fragment.generated';

export interface LinearTestTaskAttempt {
  id: number;
  status: TestTaskStatus;
  testTaskId: number;
  hasBeenPassed: boolean;
  isPeerReviewAvailable: boolean;
  peerReviewStatus?: PeerReviewStatus;
}

export enum LearnItemType {
  Video = 'Video',
  Theory = 'Theory',
  TestTask = 'TestTask',
}

export interface ChatBase {
  id: number;
  name: string;
}

export interface LinearTestTask {
  id: number;
  slug: string;
  name: string;
  flow: TestTaskFlow;
  config: MappedTaskConfig;
  xpForSolving?: number | null;
  solutionUrl?: string | null;
  externalSolutionUrl?: string | null;
  testTaskAttempt?: LinearTestTaskAttempt | null;
  numberOfSupportChatMessagesByTestTask?: number | null;
  testTaskChatWithMentor?: ChatBase | null;
  complexity?: number | null;
  programmingLanguage: string;
}

export interface LinearVideo {
  id: number;
  title?: string | null;
  vimeoUrl?: string | null;
  vimeoDuration?: number | null;
  userRating?: number | null;
}

export interface LinearTheory {
  id: number;
  content: string;
  name: string;
}

export interface UserAttempt {
  id: number;
  solvedAt?: Date | null;
  courseTopicLearnItemId: number;
}

interface AbstractLearnItem {
  id: number;
  order: number;
  type: LearnItemType;
  isOptional: boolean;
  isDeleted: boolean;
}

export interface TaskLearnItem extends AbstractLearnItem {
  type: LearnItemType.TestTask;
  testTask: LinearTestTask;
}

export interface VideoLearnItem extends AbstractLearnItem {
  type: LearnItemType.Video;
  learnVideo: LinearVideo;
  userAttempt?: UserAttempt | null;
}

export interface TheoryLearnItem extends AbstractLearnItem {
  type: LearnItemType.Theory;
  theoryFile: LinearTheory;
  userAttempt?: UserAttempt | null;
}

export type LinearLearnItem = TaskLearnItem | VideoLearnItem | TheoryLearnItem;

interface LinerModuleAccess {
  id: string;
  isGranted: boolean;
  blockedByDailyTasks: boolean;
  blockedReason?: CourseAccessBlockReason | null;
}

interface LinearCourseMeta {
  id: string;
  totalCompletionPercent?: number | null;
  access: LinerModuleAccess;
  hasCertificate?: boolean | null;
  requiredCompletionPercent?: number | null;
}

interface LinearCourseSupport {
  id: number;
  link: string;
  providerName: SupportProviderName;
}

export interface LinearModule {
  id: number;
  nameShort: string;
  slug: string;
  accessType: CourseAccessType;
  moduleType?: ModuleType | null;
  meta: LinearCourseMeta;
  config?: TestTaskConfigFragment | null;
  support?: LinearCourseSupport | null;
  courseUserTopic: CourseUserTopicBaseForLinearFragment;
  courseTopic: CourseTopicWithMetaForLinearPageFragment;
  courseUser: {
    id: number;
  };
}
