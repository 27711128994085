import {
  LearnQueryParams,
} from '@/components/platform/Learn/hooks/useLearnQueryParams';
import {
  TopicSections,
} from '@/controllers/courseUserTopics/courseUserTopics.typedefs';
import {
  LearnItemType,
  LinearLearnItem,
} from '@/components/platform/Learn/pages/LinearCourseTopic/LinearPage.typedefs';

type Result = (Partial<LearnQueryParams> & { section: TopicSections }) | null;

export const getQueryParamsByLearnItem = (
  learnItem?: LinearLearnItem | null,
): Result | null => {
  if (!learnItem) {
    return null;
  }

  switch (learnItem.type) {
    case LearnItemType.Video:
      return {
        section: TopicSections.Video,
        videoId: learnItem.learnVideo?.id,
      };

    case LearnItemType.TestTask:
      return {
        section: TopicSections.Practice,
        testTaskSlug: learnItem.testTask.slug,
      };

    case LearnItemType.Theory:
      return {
        section: TopicSections.Theory,
        theoryId: learnItem.theoryFile.id,
      };
    default:
      return null;
  }
};
