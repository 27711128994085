import { useRouterQuery } from '@/hooks/useRouterQuery';
import { topicSectionByURL } from '@/components/platform/Learn/CourseTopic/URLByTopicSection';
import { TopicSections } from '@/controllers/courseUserTopics/courseUserTopics.typedefs';
import {
  PracticeTabs,
} from '@/components/platform/Learn/pages/CourseTopic/components/MobilePractice/MobilePracticeTabs/PracticeTabs.constants';
import {
  practiceTabURLToPracticeTabs,
} from '@/components/platform/Learn/pages/CourseTopic/components/MobilePractice/MobilePracticeTabs/practiceTabURLToPracticeTabs';
import {
  LearnItemFilter,
} from '@/components/platform/Learn/LinearFlow/hooks/learnItemsFilter/useLearnItemsFilter';
import {
  AttachmentTabs,
} from '@/components/platform/TestTasks/components/AttachmentFlow/MobileAttachmentFlowTabs/AttachmentTabs.constants';
import {
  attachmentTabURLToAttachmentTabs,
} from '@/components/platform/Learn/pages/CourseTopic/components/MobilePractice/MobilePracticeTabs/attachmentTabURLToAttachmentTabs';

export const NO_MODULE = 'NO_MODULE';

export enum LearnQueryParamsKeys {
  CourseSlug = 'courseSlug',
  CourseTopicSlug = 'courseTopicSlug',
  Course = 'course',
  Module = 'module',
  TestTaskSlug = 'testTaskSlug',
  Section = 'section',
  TaskSection = 'taskSection',
  VideoId = 'videoId',
  TheoryId = 'theoryId',
  LearnItemsFilter = 'learnItemsFilter',
}

export interface LearnQueryParams {
  [LearnQueryParamsKeys.CourseSlug]: string;
  [LearnQueryParamsKeys.CourseTopicSlug]: string;
  [LearnQueryParamsKeys.Course]: string;
  [LearnQueryParamsKeys.Module]: string;
  [LearnQueryParamsKeys.TestTaskSlug]: string;
  [LearnQueryParamsKeys.Section]: TopicSections;
  [LearnQueryParamsKeys.TaskSection]: PracticeTabs | null;
  attachmentTaskSection: AttachmentTabs | null;
  [LearnQueryParamsKeys.VideoId]: number;
  [LearnQueryParamsKeys.TheoryId]: number | null;
  [LearnQueryParamsKeys.LearnItemsFilter]: LearnItemFilter;
}

const {
  CourseSlug,
  CourseTopicSlug,
  Course,
  Module,
  TestTaskSlug,
  Section,
  TaskSection,
  VideoId,
  TheoryId,
  LearnItemsFilter,
} = LearnQueryParamsKeys;

export const readLearnItemFilterParam = (value: string): LearnItemFilter => {
  switch (value) {
    case LearnItemFilter.Video:
      return LearnItemFilter.Video;

    case LearnItemFilter.Theory:
      return LearnItemFilter.Theory;

    default:
      return LearnItemFilter.All;
  }
};

export const useLearnQueryParams = (): LearnQueryParams => {
  const queryParams = useRouterQuery<LearnQueryParams>((query) => ({
    [Module]: query[Module] || NO_MODULE,
    [CourseSlug]: query[CourseSlug] || '',
    [CourseTopicSlug]: query[CourseTopicSlug] || '',
    [Course]: query[Course] || '',
    [TestTaskSlug]: query[TestTaskSlug] || '',
    [Section]: topicSectionByURL[query[Section]] ?? TopicSections.Video,
    [TaskSection]: query[TaskSection]
      ? practiceTabURLToPracticeTabs(query[TaskSection])
      : null,
    attachmentTaskSection: query[TaskSection]
      ? attachmentTabURLToAttachmentTabs(query[TaskSection])
      : null,
    [VideoId]: query[VideoId]
      ? Number(query[VideoId])
      : NaN,
    [TheoryId]: query[TheoryId]
      ? Number(query[TheoryId])
      : null,
    [LearnItemsFilter]: readLearnItemFilterParam(query[LearnItemsFilter]),
  }));

  return queryParams;
};
