import { useCallback, useEffect, useMemo } from 'react';
import {
  LearnQueryParamsKeys,
  useLearnQueryParams,
} from '@/components/platform/Learn/hooks/useLearnQueryParams';
import {
  getInitialSelectedLearnItemId,
  getSelectedLearnItemByQueryParams,
} from '@/components/platform/Learn/LinearFlow/helpers/getInitialSelectedLearnItemId';
import { useRouter } from '@/middleware/i18n';
import {
  setQueryParamsByLearnItem,
} from '@/components/platform/Learn/LinearFlow/helpers/setQueryParamsByLearnItem';
import {
  LearnItemType,
  LinearLearnItem,
} from '@/components/platform/Learn/pages/LinearCourseTopic/LinearPage.typedefs';
import {
  LearnItemFilter,
  useLearnItemsFilter,
} from '@/components/platform/Learn/LinearFlow/hooks/learnItemsFilter/useLearnItemsFilter';
import {
  PracticeTabs,
} from '@/components/platform/Learn/pages/CourseTopic/components/MobilePractice/MobilePracticeTabs/PracticeTabs.constants';

interface Options {
  learnItems: LinearLearnItem[];
  onChangeSelectedLearnItem: (learnItem: LinearLearnItem | null) => void;
  lastOpenedLearnItemId: number | null;
  isMobile: boolean;
  shouldItemBeSelectedOnMobileInitially: boolean;
}

interface HookOutput {
  selectedLearnItemId: number | null;
  selectLearnItem: (learnItemId: number | null) => void;
}

const getInitialLearnItemFilter = (
  learnItemFilter: LearnItemFilter,
  isMobile: boolean,
  initialItem?: LinearLearnItem | null,
) => {
  if (
    !initialItem
    || learnItemFilter === LearnItemFilter.All
    || isMobile
  ) {
    return LearnItemFilter.All;
  }

  if (
    initialItem.type === LearnItemType.Theory
    && learnItemFilter === LearnItemFilter.Theory
  ) {
    return LearnItemFilter.Theory;
  }

  if (
    initialItem.type === LearnItemType.Video
    && learnItemFilter === LearnItemFilter.Video
  ) {
    return LearnItemFilter.Video;
  }

  return LearnItemFilter.All;
};

export const useSelectLearnItem = (
  options: Options,
): HookOutput => {
  const {
    learnItems,
    onChangeSelectedLearnItem,
    lastOpenedLearnItemId,
    isMobile,
    shouldItemBeSelectedOnMobileInitially,
  } = options;
  const router = useRouter();
  const learnQueryParams = useLearnQueryParams();

  const selectedLearnItemId = useMemo(() => getSelectedLearnItemByQueryParams({
    learnItems,
    learnQueryParams,
  }), [learnItems, learnQueryParams]);

  const { learnItemFilter, disableFiltering } = useLearnItemsFilter();

  useEffect(() => {
    const shouldSkipInitialSelection = isMobile
      && !shouldItemBeSelectedOnMobileInitially;

    if (shouldSkipInitialSelection) {
      if (learnItemFilter !== LearnItemFilter.All) {
        disableFiltering();
      }

      return;
    }

    const itemId = getInitialSelectedLearnItemId({
      learnItems,
      learnQueryParams,
      lastOpenedLearnItemId,
      learnItemFilter,
    });

    const item = learnItems.find(
      (learnItem) => learnItem.id === itemId,
    );

    setQueryParamsByLearnItem({
      router,
      learnItem: item,
      currentLearnQueryParams: learnQueryParams,
      isEmptyParamsAvailable: isMobile,
      learnItemsFilter: getInitialLearnItemFilter(
        learnItemFilter,
        isMobile,
        item,
      ),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectLearnItem = useCallback((learnItemId: number | null) => {
    const isSameLearnItem = selectedLearnItemId === learnItemId;

    if (isSameLearnItem) {
      return;
    }

    const newSelectedItem = learnItems.find(
      (learnItem) => learnItem.id === learnItemId,
    );

    onChangeSelectedLearnItem(newSelectedItem || null);
    setQueryParamsByLearnItem({
      router,
      learnItem: newSelectedItem,
      currentLearnQueryParams: learnQueryParams,
      isEmptyParamsAvailable: isMobile,
      queryParamsToSet: {
        ...(isMobile && { // Needed to set the correct default tab on mobile
          [LearnQueryParamsKeys.TaskSection]: PracticeTabs.Editor,
        }),
      },
    });
  }, [
    selectedLearnItemId,
    learnItems,
    onChangeSelectedLearnItem,
    router,
    learnQueryParams,
    isMobile,
  ]);

  return useMemo(() => ({
    selectedLearnItemId,
    selectLearnItem,
  }), [selectLearnItem, selectedLearnItemId]);
};
