import { useMemo } from 'react';
import {
  LinearLearnItem,
} from '@/components/platform/Learn/pages/LinearCourseTopic/LinearPage.typedefs';
import {
  LearnItemsMappedByType, mapLearnItemsByType,
} from '@/components/platform/Learn/LinearFlow/helpers/mapLearnItemsByType';

export const useMappedByTypeLearnItems = (
  learnItems: LinearLearnItem[],
): LearnItemsMappedByType => useMemo(
  () => mapLearnItemsByType(learnItems),
  [learnItems],
);
