import {
  LearnQueryParams,
} from '@/components/platform/Learn/hooks/useLearnQueryParams';
import {
  TopicSections,
} from '@/controllers/courseUserTopics/courseUserTopics.typedefs';
import {
  LearnItemType, LinearLearnItem,
} from '@/components/platform/Learn/pages/LinearCourseTopic/LinearPage.typedefs';
import {
  extractTheoryLearnItems, extractVideoLearnItems,
} from '@/components/platform/Learn/LinearFlow/helpers/extractLearnItems';
import {
  LearnItemFilter,
} from '@/components/platform/Learn/LinearFlow/hooks/learnItemsFilter/useLearnItemsFilter';

interface GetInitialItemOptions {
  learnItems: LinearLearnItem[];
  learnQueryParams: LearnQueryParams;
  lastOpenedLearnItemId: number | null;
  learnItemFilter?: LearnItemFilter;
}

export const getSelectedLearnItemByQueryParams = (
  options: Pick<GetInitialItemOptions, 'learnItems' | 'learnQueryParams'>,
): number | null => {
  const {
    learnItems,
    learnQueryParams,
  } = options;

  const {
    section,
    videoId,
    testTaskSlug,
    theoryId,
  } = learnQueryParams;

  if (section === TopicSections.Video && videoId) {
    const item = learnItems.find(
      (learnItem) => learnItem.type === LearnItemType.Video
          && learnItem.learnVideo?.id === videoId,
    );

    if (item) {
      return item.id;
    }
  }

  if (section === TopicSections.Practice && testTaskSlug) {
    const item = learnItems.find(
      (learnItem) => learnItem.type === LearnItemType.TestTask
          && learnItem.testTask.slug === testTaskSlug,
    );

    if (item) {
      return item.id;
    }
  }

  if (section === TopicSections.Theory && theoryId) {
    const item = learnItems.find(
      (learnItem) => learnItem.type === LearnItemType.Theory
          && learnItem.theoryFile.id === theoryId,
    );

    if (item) {
      return item.id;
    }
  }

  if (testTaskSlug) {
    const item = learnItems.find(
      (learnItem) => learnItem.type === LearnItemType.TestTask
          && learnItem.testTask.slug === testTaskSlug,
    );

    if (item) {
      return item.id;
    }
  }

  return null;
};

export const getInitialSelectedLearnItemId = (
  options: GetInitialItemOptions,
): number | null => {
  const {
    learnItems,
    lastOpenedLearnItemId,
    learnItemFilter,
  } = options;

  const isTheoryOnlyMode = learnItemFilter === LearnItemFilter.Theory;
  const isVideoOnlyMode = learnItemFilter === LearnItemFilter.Video;

  const itemIdByQueryParams = getSelectedLearnItemByQueryParams(options);
  const itemByQureyParams = learnItems.find(
    (learnItem) => learnItem.id === itemIdByQueryParams,
  );

  if (isTheoryOnlyMode) {
    const theoryItems = extractTheoryLearnItems(learnItems);

    const item = itemByQureyParams?.type === LearnItemType.Theory
      ? itemByQureyParams
      : theoryItems[0];

    if (item) {
      return item.id;
    }
  }

  if (isVideoOnlyMode) {
    const videoItems = extractVideoLearnItems(learnItems);

    const item = itemByQureyParams?.type === LearnItemType.Video
      ? itemByQureyParams
      : videoItems[0];

    if (item) {
      return item.id;
    }
  }

  if (itemIdByQueryParams) {
    return itemIdByQueryParams;
  }

  if (lastOpenedLearnItemId) {
    const item = learnItems.find(
      (learnItem) => learnItem.id === lastOpenedLearnItemId,
    );

    if (item) {
      return item.id;
    }
  }

  return learnItems[0]?.id || null;
};
