import {
  LearnItemType,
  LinearLearnItem, TaskLearnItem, TheoryLearnItem, VideoLearnItem,
} from '@/components/platform/Learn/pages/LinearCourseTopic/LinearPage.typedefs';

export const extractTaskLearnItems = (
  learnItems: LinearLearnItem[],
): TaskLearnItem[] => learnItems.filter(
  (learnItem): learnItem is TaskLearnItem => (
    learnItem.type === LearnItemType.TestTask
  ),
);

export const extractTheoryLearnItems = (
  learnItems: LinearLearnItem[],
) => learnItems.filter(
  (learnItem): learnItem is TheoryLearnItem => (
    learnItem.type === LearnItemType.Theory
  ),
);

export const extractVideoLearnItems = (
  learnItems: LinearLearnItem[],
) => learnItems.filter(
  (learnItem): learnItem is VideoLearnItem => (
    learnItem.type === LearnItemType.Video
  ),
);
